<template>
  <div class="mx-auto PromtSettings">
    <v-tabs v-model="tab" grow  @change="changeTabs">
      <v-tab v-for="item in promtTabs" :key="item.key">
        {{ item }}
      </v-tab>
    </v-tabs>

    <div v-if="tab === 0" class="PromtSettings__settings-tab">
      <v-text-field
        outlined
        dense
        :value="promt.name"
        @input="(val) => updatePromt('name', val)"
        label="Prompt Name"
        class="mb-3"
      />

      <v-divider class="mx-n5 PromtSettings__divider" />
      <section>
        <v-select
            outlined
            label="AI Model"
            :items="models"
            v-model="promt.model"
            item-text="label"
            item-value="value"
            @change="(val) => { this.promt.model = val }"
        ></v-select>
        <v-autocomplete
            outlined
            label="System Prompt"
            :items="systemPrompts"
            v-model="promt.systemPromptId"
            item-text="name"
            item-value="id"
            @change="(val) => { this.promt.systemPromptId = val }"
            :append-outer-icon="systemPromptLoading ? 'mdi-loading mdi-spin' : 'mdi-reload'"
            @click:append-outer="listSystemPrompt"
            clearable
        ></v-autocomplete>
        <p class="text-h6 pt-4">Prompt Inputs</p>
        <div>

          <v-row v-for="(item, i) in inputs" :key="i" class="mb-1" dense>
            <v-col>
              <v-text-field
                outlined
                dense
                :value="item.name"
                @input="(val) => updatePromt(`inputs[${i}].name`, val)"
                label="Name"
              />
            </v-col>
            <v-col>
              <v-text-field
                outlined
                dense
                label="Test Value"
                :value="item.value"
                @input="(val) => updatePromt(`inputs[${i}].value`, val)"
              />
            </v-col>
            <v-col cols="auto">
              <v-btn icon @click="removeInput(i)">
                <v-icon color="error">mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-btn
            text
            :disabled="disableAddInput"
            @click="addInput"
            color="primary"
          >
            + Add Input
          </v-btn>

        </div>
        <p class="text-h6 pt-4">Prompt Attachments</p>
        <div>
          <v-row v-for="(item, i) in attachments" :key="i+'attached'" class="mb-1" dense>
            <v-col>
              <v-text-field
                  outlined
                  dense
                  :value="item.name"
                  @input="(val) => updatePromt(`attachments[${i}].name`, val)"
                  label="Name"
              />
            </v-col>
            <v-col>
              <v-file-input
                  v-if="typeof item.value !== 'string' || !item.value"
                  outlined
                  dense
                  label="Test Value"
                  :value="item.value"
                  @change="(val) => updatePromtFile(`attachments[${i}].value`, val)"
              />
              <v-text-field
                  v-if="typeof item.value === 'string' && item.value"
                  outlined
                  dense
                  label="Test Value"
                  :value="item.value"
                  @input="(val) => updatePromt(`attachments[${i}].value`, val)"
              />
            </v-col>
            <v-col cols="auto">
              <v-btn icon @click="removeAttachment(i)">
                <v-icon color="error">mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-btn
              text
              :disabled="disableAddAttachment"
              @click="addAttachment"
              color="primary"
          >
            + Add Attachment
          </v-btn>

        </div>
      </section>
      <span  >Output:</span>
      <json-viewer
      v-if="this.output !== undefined && this.output != ''"
      :value="this.output"
      :expand-depth="0"
      boxed
      sort
      copyable
    ></json-viewer>

    </div>
    <div v-if="tab === 1" class="PromtSettings__settings-tab">
      <section>
        <v-row class="mb-3" align="center" justify="center" v-for="item in promt.tools" :key="item.id">
          <v-col class="tool_col">
            <p class="font-weight-bold">
              {{item?.name}}
            </p>
          </v-col>
          <v-col class="tool_edit">
            <v-btn color="primary">
              <router-link
                  :to="`/tools/${item?.id}`"
                  tag="span"
                  class="Tool__header-title-link"
              >
                Edit Tool
              </router-link>
            </v-btn>
          </v-col>
          <v-col class="tool_del" >
            <v-btn width="25" icon color="error" @click.stop="deleteTool(item?.id)">
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-divider class="mx-n5 PromtSettings__divider" />
        <v-autocomplete
            ref="toolSelect"
            class="mb-3"
            outlined
            dense
            @input="(val) => setNewTool(val)"
            item-text="name"
            item-value="id"
            :items="tools"
            label="Add Tool"
            :append-outer-icon="fetchToolsLoading ? 'mdi-loading mdi-spin' : 'mdi-reload'"
            @click:append-outer="listTools"
            clearable></v-autocomplete>
      </section>
    </div>
  </div>
</template>

<script>
import JsonViewer from 'vue-json-viewer';
import { createNamespacedHelpers } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import lodashSet from 'lodash/set';

const { mapGetters: promtGetters, mapMutations: promtMutations } =
  createNamespacedHelpers('promts');
const {  mapGetters: toolsGetters, mapActions: toolsActions } =
    createNamespacedHelpers('promptsTools');

const {  mapGetters: AIChatGetters, mapActions: AIChatActions } =
    createNamespacedHelpers('AIChat');
  
export default {
  name: 'promtSettings',
  data() {
    return {
      models: [
        {
          label: 'Claude V3 Sonnet',
          value: 'anthropic.claude-3-sonnet-20240229-v1:0'
        },
        {
          label: 'Claude 3.5 Sonnet',
          value: 'anthropic.claude-3-5-sonnet-20240620-v1:0'
        },
        {
          label: 'Claude 3.5 Sonnet v2',
          value: 'anthropic.claude-3-5-sonnet-20241022-v2:0'
        },
        {
          label: 'Claude V3 Haiku',
          value: 'anthropic.claude-3-haiku-20240307-v1:0'
        },
        {
          label: 'Claude V3.5 Haiku',
          value: 'anthropic.claude-3-5-haiku-20241022-v1:0'
        },
      ],
      selectedModel: {
        label: 'Claude V3 Haiku',
        value: 'anthropic.claude-3-haiku-20240307-v1:0'
      },
      tab: 0,
      videoDialog: false,
      videoLinks: [],
    };
  },
  components: {
    JsonViewer,
  },
  computed: {
    ...promtGetters({
      promt: 'PROMT',
      output:'OUTPUT',
      settings: 'PROMT_SETTINGS',
      inputs: 'PROMT_INPUTS',
      attachments: 'PROMPT_ATTACHMENTS',
      action: 'PROMT_ACTION',
      promtLogs: 'PROMT_LOGS',
      runningPromt: 'RUNNING_PROMT',    
    }),
    ...AIChatGetters({
      systemPrompts: 'SYSTEM_PROMPTS',
      systemPromptLoading: 'SYSTEM_PROMPT_LOADING',
    }),
    ...toolsGetters({
      tools: 'TOOLS',
      fetchToolsLoading: 'FETCH_TOOLS_LOADING',
    }),
    disableAddInput() {
      const inputs = this.inputs;
      if (!inputs) return true;
      if (!inputs[0]) return false;
      const lastInput = inputs[inputs.length - 1];
      return !lastInput?.name || !lastInput?.value;
    },
    disableAddAttachment() {
      const inputs = this.attachments;
      if (!inputs) return true;
      if (!inputs[0]) return false;
      const lastInput = inputs[inputs.length - 1];
      return !lastInput?.name || !lastInput?.value;
    },
    promtTabs() {
      return ['Settings', 'Tools'];
    },
  },
  methods: {
    ...promtMutations({
      setInputs: 'SET_INPUTS',
      setAttachments: 'SET_ATTACHMENTS',
      setPromt: 'SET_PROMT',
      setOutput: 'SET_OUTPUT',
    }),
    ...toolsActions({
      fetchTools: 'fetchTools'
    }),
    ...AIChatActions(['getSystemPrompts']),
    changeTabs(value) {
      this.tab = value;
    },
    removeInput(index) {
      this.inputs.splice(index, 1);
    },
    addInput() {
      const inputs = cloneDeep(this.inputs);
      inputs.push({ name: '', value: '' });
      this.setInputs(inputs);
    },
    removeAttachment(index) {
      this.attachments.splice(index, 1);
    },
    addAttachment() {
      const attachments = cloneDeep(this.attachments);
      attachments.push({ name: '', value: '' });
      this.setAttachments(attachments);
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      })
    },
    async updatePromt(prop, value)
    {
      const promt = cloneDeep(this.promt);
      lodashSet(promt, prop, value);
      this.setPromt(promt);
    },
    async updatePromtFile(prop, value)
    {
      value = await this.toBase64(value);
      const promt = cloneDeep(this.promt);
      lodashSet(promt, prop, value);
      this.setPromt(promt);
    },
    async listTools() {
      await this.fetchTools()
    },
    async listSystemPrompt() {
      await this.getSystemPrompts();
    },
    setNewTool(val) {
      const exists = this.promt.tools.find((tool) => tool?.id === val)
      if(exists || !val) {
        return;
      }
      const tool = this.tools.find((tool) => tool?.id === val);
      this.updatePromt('tools', [...this.promt.tools, tool]);
    },
    deleteTool(val) {
      const tools = this.promt.tools.filter((tool) => tool?.id !== val);
      this.updatePromt('tools', [...tools]);
    },
  },
};
</script>

<style lang="scss" scoped>
@import './promt-settings';
</style>
